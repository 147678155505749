import request from '../utils/request'
import baseURL from '../utils/config'

//支付设置数据列表
export function GetList(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_PayWay/GetList',
        method: 'get',
        params:data
    })
}
//设置默认
export function SetDefault(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_PayWay/SetDefault',
      method: 'get',
      params:data
  })
}
//设置是否启用
export function SetEnable(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_PayWay/SetEnable',
      method: 'get',
      params:data
  })
}