<template>
  <div class="setarea-continer">
    <div class="setarea-center">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="name" label="支付方式" width="180">
        </el-table-column>
        <el-table-column prop="type" label="类型"> </el-table-column>
        <el-table-column prop="enable" label="关闭/开启" width="85">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.enable"
              active-color="#13ce66"
              inactive-color="#ff4949"
                @change="handleStatusChange(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
       <el-table-column label="" prop="isDefault" align="center" >
     <template scope="scope">
       <el-radio :label="true" v-model="scope.row.isDefault"
                 @change.native="getCurrentRow(scope.row)">设为默认收款</el-radio>
     </template>
   </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {GetList,SetDefault,SetEnable} from '../../api/paytype'
export default {
  data() {
    return {
      tableData: [],
      radio: '',
    };
  },
  methods:{
     getCurrentRow(row){ 
        // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
        // this.templateSelection = row
        console.log(row)
        let data={
          id:row.payWayId
        }
        SetDefault(data).then(res=>{
          console.log(res)
          if(res.code===10000){
            this.$message('设置成功')
             this.get()
          }
        }).catch(err=>{
          console.log(err)
        })
    },
    //获取列表数据
    get(){
      let data={}
      GetList(data).then(res=>{
        console.log(res)
        if(res.code===10000){
          this.tableData=res.data
        }
      }).catch(err=>{
        console.log(err)
      })
    },
           //设置是否启用
        handleStatusChange(row){
            console.log(row)
            let data={
              id:row.payWayId,
              enable:row.enable == true? 1 : 0
            }
            SetEnable(data).then(res=>{
              console.log(res)
              if(res.code===10000){
                this.$message('执行成功')
              }
            }).catch(err=>{
              console.log(err)
            })
        }
  },
  created(){
    this.get()
  }
};
</script>

<style scoped>
.setarea-continer{
  width: 100%;
  height: 100%;
  background: white;
}
.setarea-center {
  width: 80%;
  margin:10px auto;
}
</style>
